<template>

    <div>
        <section class="selfcare-title d-flex">
            <div class="px-2">
                <a @click="goBack()" class="custom-link text-light">
                    <i class="bi bi-arrow-left"></i>
                </a>
            </div>
            <div class="text-light width-available text-center">

            </div>
        </section>
        <section class="p-2 terms">
            <div style="color: var(--white);" class="container ">
                <h6 class="pb-3 text-center">TERMS AND CONDITIONS OF THE AFFILIATE PROGRAM
                </h6>
                <p>The affiliate program allows you to earn monthly income just by helping us attract more players to
                    our platform.</p>
                <p>As a member of our affiliate network, you'll earn a commission based on a revenue share model,
                    receiving 35% of the revenue generated.
                </p>
                <p>Through the affiliate program, you can earn a maximum limit of 15,000 Kenyan Shillings on all your
                    affiliates.
                </p>
                <p>There are no costs involved in inviting friends. The personal link is free from our site.</p>
                <p>To be eligible to use the affiliate program, you need to be 18 years or more.
                </p>
                <p>The promotion guidelines are on the Referral Link tab or any other placed as the management may
                    decide to display. Copy the link and share it with friends on any social media platform</p>
                <p>Affiliates must comply with all relevant laws and regulations, including advertising standards and
                    gambling laws.
                </p>
                <p>You are not allowed to forward our link to minors. Failure to which may lead to legal actions against
                    the sender.
                </p>
                <p>Under the Falcon Bet Bonus policy, you can only claim a maximum of Kes. 15,000 as referral bonus.
                </p>
                <p>The account balance will be transferred to available balance upon wagering the amount 30 times.</p>
                <p>The bonus will be credited to the customer's account automatically after the deposit is made.</p>
                <p>The referral bonus must be wagered within 8 days.</p>
                <p>If the 8 days expires without customer’s compliance on the redemption policy above, all bonuses and
                    winnings will be removed from account
                </p>
                <p>The offer is not valid in conjunction with any other promotions or special offers.
                </p>
                <p>If the company in its own discretion believes /suspects a customer is misusing the referral bonus, or
                    in conduct of foul play and/or participation in strategies that the company in its sole discretion
                    deems to be abusive, the company reserves the right to cancel the bonus and all the accrued
                    benefits, without any explanation.
                </p>
                <p>The bonus may be canceled at any time without prior notice, but only before the referral bonus amount
                    is wagered or withdrawn. In cases of dispute, the decision of the company is deemed to be final and
                    binding.
                </p>
                <p>The bonus is deemed to have been wagered if the customer's remaining account balance is less than the
                    minimum stake, or if the wagering requirement has been met in full.
                </p>
                <p>The referral bonus will be awarded only after the referred customers make a deposit and wager has
                    been credited to the customer's account. If the deposit is withdrawn before wager, the referral
                    bonus will be withheld and reversed accordingly.
                </p>
                <p>The customer must provide ID documents, when necessary, to validate their identity (KYC). Failure to
                    produce these documents if requested will result in the referral bonus and any winnings being
                    forfeited. The Company reserves the right to request, at any time, that the customer provides
                    photographic evidence of themselves holding their ID (the customer’s face must be clearly visible in
                    the photo) or undergoes identification via telephone.
                </p>
                <p>Falcon Bet has the right to freeze funds and close the customer's account if any fraudulent or money
                    laundering activities are detected. </p>
                <p>The offer is only available to customers with one user account.</p>
                <p>Savvy Bet Limited T/A Falcon Bet has the right to amend the terms of the offer, cancel or renew the
                    offer, or refuse to allow participation at any time without prior notice. </p>

            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "TermsAffiliates"
}
</script>
<style scoped>
body {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1,
h2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.5em;
}

p {
    margin-bottom: 10px;
}

.definition-list {
    margin-top: 20px;
}

.definition-list dt {
    font-weight: bold;
}

.definition-list dd {
    margin-bottom: 10px;
}
</style>